<template>
  <div style="height:100vh">
    <div id="demo"></div>
  </div>
</template>
<script>
import Pdfh5 from "pdfh5";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      Pdfh5: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.pdfh5 = new Pdfh5("#demo", {
      pdfurl: this.$store.state.pdfUrl,
    });
  },
  methods: {},
};
</script>
<style src='../../node_modules/pdfh5/css/pdfh5.css' >
#demo {
  height: 100vh;
}
</style>